import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import * as VueGoogleMaps from 'vue2-google-maps'
import { UrlBase, Version, PortalName } from './config'
import './assets/scss/app.scss'
// import './assets/css/main.css'
import './assets/css/quill.snow.css'

import Maska from 'maska'
Vue.use(Maska)

Vue.use(Buefy)

Vue.use(VueGoogleMaps as any, {
  load: {
    key: 'AIzaSyDm50mrZ6W4sJArDAYGVIrSNx5l-ljzx4o'
  },
  // Demonstrating how we can customize the name of the components
  installComponents: true,
});


Vue.config.productionTip = false

Vue.prototype.$urlBase = UrlBase()
Vue.prototype.$version = Version()
Vue.prototype.$portalName = PortalName

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import { ComponentVersion } from '@/components/version'

export const SYSTEM_PROD_MODE = false

export const VERSION = 'Versão RC-1.1.6'+ComponentVersion

export const PortalName = "Prefeitura Municipal de XXXX...."
export const PortalSite = "www.xxxxxx.es.gov.br"

// https://devapitransparencia.laranjadaterra.es.gov.br
// https://apitransparencia2.laranjadaterra.es.gov.br
export const API_BASE = {
	DEV:'https://apitransparencia2.laranjadaterra.es.gov.br',
	PROD:'https://apitransparencia2.laranjadaterra.es.gov.br',
	// DEV:'https://devapitransparencia.laranjadaterra.es.gov.br',
	// PROD:'https://devapitransparencia.laranjadaterra.es.gov.br',
	// DEV:'https://aps.laranjadaterra.es.gov.br/api/core',
	// PROD:'https://aps.laranjadaterra.es.gov.br/api/core',
}

export function UrlBase(): string {
	if(SYSTEM_PROD_MODE){
		return API_BASE.PROD
	} console.warn("[main] System in Devmode")
	return API_BASE.DEV
}

export function Version(): string{
	if(SYSTEM_PROD_MODE == false){
		return `Devmode | ${VERSION}`
	} return VERSION
}

export function setConfigSidebar(value: any): void {
	return localStorage.setItem("configSidenav", `${value}`);
}

export function getConfigSidebar(): string | null{
	return localStorage.getItem("configSidenav");
}
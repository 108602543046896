import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [      
	// pubic
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/Login.vue')
	},
	// admin
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/admin/home/Home.vue')
	},
	{
		path: '/transparencia/licitacoes',
		name: 'transparencia-licitacoes',
		component: () => import('@/views/admin/transparencia/licitacoes/Licitacoes.vue')
	},
	{
		path: '/transparencia/licitacoes-add',
		name: 'transparencia-licitacoes-add',
		component: () => import('@/views/admin/transparencia/licitacaoId/LicitacaoId.vue')
	},
	{
		path: '/transparencia/licitacoes/:id',
		name: 'transparencia-licitacoes-id',
		component: () => import('@/views/admin/transparencia/licitacaoId/LicitacaoId.vue')
	},
	{
		path: '/transparencia/selecoes',
		name: 'transparencia-concursos',
		component: () => import('@/views/admin/concursos/concursos/Concursos.vue')
	},
	{
		path: '/transparencia/selecoes/:id',
		name: 'transparencia-concursos-id',
		component: () => import('@/views/admin/concursos/concursosId/ConcursosId.vue')
	},
	{
		path: '/transparencia/selecoes-add',
		name: 'transparencia-concursos-add',
		component: () => import('@/views/admin/concursos/concursosId/ConcursosId.vue')
	},
	{
		path: '/categoria-documentos',
		name: 'categoria-documentos',
		component: () => import('@/views/admin/categoriaDocumentos/CategoriaDocumentos.vue')
	},
	{
		path: '/tipo-documento/:id',
		name: 'tipo-documento-id',
		component: () => import('@/views/admin/documentos/documentos/Documentos.vue')
	},
	{
		path: '/tipo-documento/:idTipo/documento/:idDocumento',
		name: 'tipo-documento-id-documento',
		component: () => import('@/views/admin/documentos/documentosId/DocumentosId.vue')
	},
	{
		path: '/tipo-documento/:idTipo/add-documento',
		name: 'tipo-documento-novo-documento',
		component: () => import('@/views/admin/documentos/documentosId/DocumentosId.vue')
	},
	{
		path: '*',
		name: '404',
		component: () => import('@/views/public/page404/Page404.vue')
	},
	// {
	// 	path: '/test',
	// 	name: 'test',
	// 	component: () => import('@/views/test/Test.vue')
	// }
]

export default routes